<template>
  <Layout>
		<ContactForm />
  </Layout>
</template>

<script>
import Layout from "../../layouts/public";
import appConfig from "@/app.config";
import ContactForm from '@/components/contact-form';

export default {
  page: {
    title: "Contact Us",
    meta: [{ name: "description", content: appConfig.description }]
  },
	
  components: { 
		Layout,
		ContactForm,
	},
};
</script>